/* base styles */
.navbar-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
}
.navbar-inside {
    align-items: center;
    background: linear-gradient(to bottom, rgba(197, 224, 184, 1), rgba(197, 224, 184, 0));
    top: 0;
    z-index: 800;
    height: 85px;
    overflow: visible;
    color: #1a1a1a;
    transition: 0.5s;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.homelink {
    margin: auto;
    padding-top: 20px;
}
.homelink img {
    width: 200px;
}
.navbar-inside a:link,
a:visited {
    text-decoration: none;
    color: #1a1a1a;
}
.navbar-inside a:hover {
    color: #a61b0f;
}
#navbar-small-logo {
    height: 100%;
}
.navbut {
    margin: 20px;
    position: absolute;
    top: 0px;
    cursor: pointer;
}
.leftnavbut {
    padding-left: 20px;
    height: 64px;
}
.rightnavbut {
    padding: 10px;
    right: 0px;
}
.navbut:hover {
    color: #a61b0f;
}
@media (max-width: 575px) {
    .navbut {
        top: 3px;
    }
    .leftnavbut {
        padding-left: 0px;
        top: 5px;
        height: 50px;
    }
}
/* The side navigation menu */

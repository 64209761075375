.join-text {
    padding: 20px;
}
.join-heading {
    margin-top: 10px;
}

#join-giggle-form-div {
    color: #426b2e;
    display: none;
}
#registered-container {
    display: none;
}
#member-exists-error {
    display: none;
}
.registered-content {
    font-size: 1rem;
}

.join-form {
    padding: 30px;
}

.join-button-container {
}

.join-button {
    width: 50%;
    background-color: #487532;
    color: white;
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
    margin: auto;
}

.join-button:hover {
    /* border-radius: 40px; */
    box-shadow: 2px 2px 5px gray;
    background-color: #426b2e;
    color: #ffffff;
    /* transform: scale(5.5); */
}

.join-the-giggle-form-errors {
    display: none;
    /* visibility: hidden; */
    font-size: 0.8rem !important;
    color: #91180d;
}
@media (max-width: 575px) {
    .join-form {
        padding-top: 0;
    }
    .join-details {
        padding: 0.7rem;
    }
}
@media (min-width: 576px) {
    .join-form {
        padding-top: 50px;
    }
    .join-details {
        padding: 1.7rem;
    }
}

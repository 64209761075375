.contact-form-mb {
    margin-bottom: 0 !important;
}
/* .contact-form-textarea-container {
} */
.contact-form-textarea {
    height: 150px;
}

.contact-form-submit-button {
    width: 100%;
    background-color: #487532;
    color: rgb(238, 238, 238);
}
.contact-form-submit-button:hover {
    background-color: #426b2e;
    color: white;
    box-shadow: 2px 2px 10px gray;
}
.contact-form-status {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}
@media (max-width: 767px) {
    .contact-us-form {
        width: auto;
    }
}

@media (min-width: 768px) {
    .contact-us-form {
        width: 65%;
    }
}

/* base styles */
.home-container > * {
    z-index: 1;
}

#bg {
    position: fixed;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    z-index: -1;
}

#bg img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    min-width: 100%;
    min-height: 50%;
}

.home-container {
    /* height: 100vh; */
    /* width: 100%; */
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    /* z-index: -99; */
}
.menu-button {
    margin: 20px;
    position: fixed;
    top: 0px;
    cursor: pointer;
    right: 0px;
    z-index: 1;
    color: white;
}
.menu-button:hover {
    color: #a61b0f;
}
#rest-of-page {
    transition: 0.5s;
}

.element {
    margin: auto;
    margin-top: 5%;
    /* max-width: 1440px; */
}
.elements {
    margin-bottom: 5vh;
}

/* ------- Element 0 ------- */
.element0 {
    margin-top: 0;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.small-logo {
    margin-top: 20px;
    position: fixed;
    top: 0px;
    cursor: pointer;
    left: 0px;
    z-index: 1;
}

.home-logo {
    position: absolute;
    /* bottom: 50%; */
}

@media (max-width: 575px) {
    #bg {
        display: block !important;
    }

    .home-container {
        /* background-image: url("../media/bg1-mobile.jpg"); */
    }
    .home-logo {
        width: 250px;
        height: 90px;
        top: 30%;
    }
    .small-logo {
        width: 70px;
    }
    .element {
        width: 90%;
    }
    .scroll-down {
        margin-bottom: 60px;
    }
}
@media (min-width: 576px) {
    .home-container {
        background-image: url("../media/bg2.jpg");
    }
    .home-logo {
        width: 700px;
        height: 252px;
        transform: translateY(-50%);
        top: 45%;
    }
    .small-logo {
        width: 140px;
    }
    .element {
        width: 80%;
    }
    .scroll-down {
        margin-bottom: 2%;
    }
    .home-sidebar-toggle {
        fill: white;
    }
}
.scroll-down {
    margin-top: auto;
    margin-left: auto;
    animation-name: bling;
    animation-timing-function: linear;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
/* @media (max-width: 575px) {
    .scroll-down {
        margin-bottom: 70px;
    }
}
@media (min-width: 576px) {
    .scroll-down {
        margin-bottom: 2%;
    }
} */
@keyframes bling {
    from {
        width: 50px;
        padding-right: 10px;
    }
    to {
        width: 60px;
        padding-right: 0px;
    }
}

/* ------- Element A ------- */
.elementA {
    background-color: rgba(38, 62, 27, 0.7);
    color: white;
}
@media (max-width: 575px) {
    .elementA {
        padding: 5px;
    }
}
@media (min-width: 576px) {
    .elementA {
        padding: 15px;
    }
}

/* Slideshow.css */

/* ------- Element B ------- */
.elementB {
    background-image: url("../media/home-join.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 50%;
}
@media (max-width: 575px) {
    .elementB {
        border: blanchedalmond 5px solid;
    }
}
@media (min-width: 576px) {
    .elementB {
        border: blanchedalmond 10px solid;
    }
}

/* ------- Element C ------- */
.elementC {
    padding: 30px;
    background-color: rgba(121, 44, 37, 0.7);
    color: #f1f1f1;
}
.points-heading {
}
.points-tagline {
}

.points-details {
    padding: 30px;
}

/* ------- Footer Padding ------- */
@media (max-width: 575px) {
    .home-container {
        padding-bottom: 450px;
    }
}
@media (min-width: 576px) {
    .home-container {
        padding-bottom: 45%;
    }
}
@media (min-width: 860px) {
    .home-container {
        padding-bottom: 30%;
    }
}
@media (min-width: 1280px) {
    .home-container {
        padding-bottom: 20vw;
    }
}

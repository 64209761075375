.about-container {
    background-image: url("../media/about-bg1.jpg");
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    margin: auto;
    padding-top: 15vh !important;
}
/* .about-1 > .about-image-left {
} */
@media (max-width: 575px) {
    .about-heading {
        display: flex;
    }
    .about-heading-content {
        font-size: 2rem;
        margin: auto;
        padding-bottom: 7vh;
    }
    .about-content {
        width: 100%;
    }
    .about-content > div {
        font-size: 2.5vmax;
    }
    .about-image-container {
        display: flex;
        overflow: hidden;
        height: 50vh;
    }
    .about-images {
        object-fit: cover;
        min-height: 100%;
        max-width: 100%;
    }
    .about-text {
        padding: 5vw 5vw 10vh 5vw;
        text-align: center;
    }
}

@media (min-width: 576px) {
    .about-heading {
        display: flex;
    }
    .about-heading-content {
        font-size: 3.5rem;
        margin: auto;
        padding-bottom: 7vh;
    }
    .about-content {
        position: relative;
        display: flex;
        width: 100%;
        height: 65vh;
    }
    .about-content > div {
        font-size: 1.5vmax;
        width: 50%;
    }
    .about-image-container {
        display: flex;
        overflow: hidden;
        height: 65vh;
    }
    .about-2 {
        right: 0;
        position: absolute;
    }
    .about-images {
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
    }
    .about-text {
        padding: 6.5vh 3.5vw 0 3.5vw;
    }
    .about-text-left {
        text-align: right;
    }
    .about-text-right {
        text-align: left;
    }
}

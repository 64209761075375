.footer-container {
    padding-top: 50px;
    margin: 0;
    background: url("../media/footer-bg1.png");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 825;
    color: #1a1a1a;
    width: 100%;
}
.footer-content {
    max-width: 90%;
    color: #f2f2f2;
    font-size: 1.2rem;
}

/*--------- FIRST ROW --------- */
.first-row {
    padding: 20px;
}
.first-row div {
    margin: auto;
}
.contact-us {
    margin-bottom: 7px;
    font-size: 1.5rem;
}
.first-row-textB > div {
    display: flex;
}
.footer-links {
    width: auto;
    padding: 0 10px;
}
.footer-links a {
    text-decoration: none;
    color: #f2f2f2;
}
.footer-links a:hover {
    color: #b0b0b0;
}
.first-row-textB {
    padding-top: 15px;
}

/*--------- MIDDLE ROW --------- */
.middle-row {
    margin-bottom: 20px;
    position: relative;
    border-bottom: solid 2px #eaeaea;
}
.footer-logo {
    margin: auto;
    width: 15vw;
    margin-bottom: 1vh;
}
#footer-logo-image {
    width: 100%;
}

/*--------- LAST ROW --------- */
.last-row {
    color: #f2f2f2;
    margin: auto;
}
.last-row div {
    /* margin: auto; */
}
.icons {
    transition: 0s;
}
.icons:hover {
    color: #f2f2f2;
}

/*--------- BREAK AT 545px--------- */
@media (max-width: 545px) {
    .last-row div {
        margin-bottom: 10px;
    }
    .middle-row {
        /* margin: auto; */
    }
    .footer-links {
        font-size: 18px;
    }
}

/*--------- BREAK AT 575px--------- */
@media (max-width: 575px) {
    .first-row-textA,
    .first-row-textB {
        text-align: center !important;
    }
}

/* -------------------- */
@media (min-width: 576px) {
    .first-row-textA {
        text-align: left !important;
    }
    .first-row-textB {
        text-align: right !important;
    }
}

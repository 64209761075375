.order-button-container {
    z-index: 801;
    position: fixed;
    transition: 0.1s;
    font-size: 2rem !important;
}
.order-button {
    font-size: 1.2rem;
    animation-name: order-button-bg;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.order-button-a {
    text-decoration: none;
    animation-name: order-button-a;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@media (max-width: 1019px) {
    .order-button {
        font-size: calc(0.3rem + 1vw) !important;
    }
}
@media (min-width: 1020px) {
    .order-button {
        font-size: 1.2rem;
    }
}

@media (max-width: 899px) {
    .order-button-container {
        width: 100%;
        bottom: 0;
        z-index: 900;
    }
    .order-button {
        height: 40px;
        border-radius: 0;
        border: 0px solid transparent;
    }
    .footer-container {
        padding-bottom: 40px;
    }
}

@media (min-width: 900px) {
    .order-button-container {
        width: 10%;
        padding: 0 10px;
        bottom: 30px;
        left: 0;
    }
    .order-button:hover {
        background-color: #426b2e;
        box-shadow: 2px 2px 5px black;
    }
}

@keyframes order-button-bg {
    0% {
        background-color: #1a1a1a;
    }
    40% {
        background-color: #487532;
    }
    80% {
        background-color: #a61b0f;
    }
    100% {
        background-color: #f2f2f2;
    }
}
@keyframes order-button-a {
    0% {
        color: white;
    }
    40% {
        color: white;
    }
    80% {
        color: white;
    }
    100% {
        color: black;
    }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

html {
    margin: 0;
    padding: 0;
}
body {
    margin: 0;
    padding: 0;
}
.app {
    margin: 0;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    position: relative;
}

a {
    /* This could be an issue if removed, however when removed I don't see any changes. -Karl, 27/06/2021 */
    color: #1a1a1a;
    text-decoration: none;
}
.link:hover {
    color: black;
}
.dutchie--iframe {
    width: 100%;
    min-width: 100%;
    border: none;
    height: 720px !important;
}
#center-pages > div {
    position: relative;
    padding-top: calc(10vh + 10px);
}

@media (max-width: 575px) {
    #center-pages > div {
        padding-bottom: 400px;
    }
}
@media (min-width: 576px) {
    #center-pages > div {
        padding-bottom: 45%;
    }
}
@media (min-width: 860px) {
    #center-pages > div {
        padding-bottom: 30%;
    }
}
@media (min-width: 1280px) {
    #center-pages > div {
        padding-bottom: 20vw;
    }
}
#dutchie-shop > div {
    padding-top: 30px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/*
 *  STYLE 13
 */

*::-webkit-scrollbar-track {
    background-color: #ffffff;
}

*::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
    background-color: #c4faa8;
    background-image: -webkit-linear-gradient(
        20deg,
        transparent,
        rgba(0, 0, 0, 0.3) 10%,
        transparent,
        transparent
    );
}

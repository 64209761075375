.shop-heading {
    margin: auto;
}
.shop-content {
    /* width: 90%; */
    margin: auto;
}

#carouselExampleControls {
    padding: 0;
}
.carousel-image {
    /* height: 50vh; */
    width: 100%;
}
.carousel > button {
    width: 8%;
    opacity: 0.8;
}
.shop-bottom {
    padding: 20px;
    background-color: antiquewhite;
    color: black;
}

@media (max-width: 575px) {
    .shop-heading {
        padding-bottom: 5px;
    }
    .shop-text {
        font-size: 1.2rem;
    }
    .shop-button {
        background-color: antiquewhite;
        width: 100%;
        border: 2px black solid;
        color: black !important;
        padding: 4px 20px;
        text-align: center;
        text-decoration: none;
        font-size: 1rem;
        cursor: pointer;
    }
    .shop-button:hover {
        background-color: #487532;
        border: 1px #487532 solid;
        color: rgb(255, 255, 255) !important;
        padding: 5px 20px;
        box-shadow: 2px 2px 5px gray;
    }
}
@media (min-width: 576px) {
    .shop-heading {
        padding-bottom: 15px;
    }
    .shop-text {
        font-size: 1.2rem;
    }
    .shop-button {
        background-color: antiquewhite;
        width: 80%;
        border: 2px black solid;
        color: black !important;
        padding: 4px 20px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
    }
    .shop-button:hover {
        background-color: #487532;
        border: 1px #487532 solid;
        color: rgb(255, 255, 255) !important;
        padding: 5px 20px;
        box-shadow: 2px 2px 5px gray;
    }
}

/* base styles */
@media (max-width: 575px) {
  .banner-text {
    font-size: 20px;
  }
  .img {
    width: 100%;
  }
  .events-container {
    padding: 5px;
    padding-top: 10px;
  }
  .pos-heading {
    margin-left: 5vw;
    font-size: 20px;
  }
  .events {
    font-size: 18px;
  }
}

@media (min-width: 576px) {
  .banner-text {
    font-size: 30px;
  }
  .img {
    width: 100%;
  }
  .events-container {
    padding: 20px;
  }
  .pos-heading {
    margin-left: 5vw;
    font-size: 30px;
  }
  .events {
    font-size: 20px;
  }
  .position {
    margin-left: 10px;
  }
}

.events-container {
  margin-top: 30px;
  margin-bottom: 200px;
}
.events {
  margin-left: 10vw;
}

@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
html {
    margin: 0;
    padding: 0;
}
body {
    margin: 0;
    padding: 0;
}
.app {
    margin: 0;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    position: relative;
}

a {
    /* This could be an issue if removed, however when removed I don't see any changes. -Karl, 27/06/2021 */
    color: #1a1a1a;
    text-decoration: none;
}
.link:hover {
    color: black;
}
.dutchie--iframe {
    width: 100%;
    min-width: 100%;
    border: none;
    height: 720px !important;
}
#center-pages > div {
    position: relative;
    padding-top: calc(10vh + 10px);
}

@media (max-width: 575px) {
    #center-pages > div {
        padding-bottom: 400px;
    }
}
@media (min-width: 576px) {
    #center-pages > div {
        padding-bottom: 45%;
    }
}
@media (min-width: 860px) {
    #center-pages > div {
        padding-bottom: 30%;
    }
}
@media (min-width: 1280px) {
    #center-pages > div {
        padding-bottom: 20vw;
    }
}
#dutchie-shop > div {
    padding-top: 30px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/*
 *  STYLE 13
 */

*::-webkit-scrollbar-track {
    background-color: #ffffff;
}

*::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
    background-color: #c4faa8;
    background-image: -webkit-linear-gradient(
        20deg,
        transparent,
        rgba(0, 0, 0, 0.3) 10%,
        transparent,
        transparent
    );
}

/* base styles */
.home-container > * {
    z-index: 1;
}

#bg {
    position: fixed;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    z-index: -1;
}

#bg img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    min-width: 100%;
    min-height: 50%;
}

.home-container {
    /* height: 100vh; */
    /* width: 100%; */
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: 0.5s;
    /* z-index: -99; */
}
.menu-button {
    margin: 20px;
    position: fixed;
    top: 0px;
    cursor: pointer;
    right: 0px;
    z-index: 1;
    color: white;
}
.menu-button:hover {
    color: #a61b0f;
}
#rest-of-page {
    transition: 0.5s;
}

.element {
    margin: auto;
    margin-top: 5%;
    /* max-width: 1440px; */
}
.elements {
    margin-bottom: 5vh;
}

/* ------- Element 0 ------- */
.element0 {
    margin-top: 0;
    height: 90vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}
.small-logo {
    margin-top: 20px;
    position: fixed;
    top: 0px;
    cursor: pointer;
    left: 0px;
    z-index: 1;
}

.home-logo {
    position: absolute;
    /* bottom: 50%; */
}

@media (max-width: 575px) {
    #bg {
        display: block !important;
    }

    .home-container {
        /* background-image: url("../media/bg1-mobile.jpg"); */
    }
    .home-logo {
        width: 250px;
        height: 90px;
        top: 30%;
    }
    .small-logo {
        width: 70px;
    }
    .element {
        width: 90%;
    }
    .scroll-down {
        margin-bottom: 60px;
    }
}
@media (min-width: 576px) {
    .home-container {
        background-image: url(/static/media/bg2.909c5fed.jpg);
    }
    .home-logo {
        width: 700px;
        height: 252px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        top: 45%;
    }
    .small-logo {
        width: 140px;
    }
    .element {
        width: 80%;
    }
    .scroll-down {
        margin-bottom: 2%;
    }
    .home-sidebar-toggle {
        fill: white;
    }
}
.scroll-down {
    margin-top: auto;
    margin-left: auto;
    -webkit-animation-name: bling;
            animation-name: bling;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-duration: 1000ms;
            animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}
/* @media (max-width: 575px) {
    .scroll-down {
        margin-bottom: 70px;
    }
}
@media (min-width: 576px) {
    .scroll-down {
        margin-bottom: 2%;
    }
} */
@-webkit-keyframes bling {
    from {
        width: 50px;
        padding-right: 10px;
    }
    to {
        width: 60px;
        padding-right: 0px;
    }
}
@keyframes bling {
    from {
        width: 50px;
        padding-right: 10px;
    }
    to {
        width: 60px;
        padding-right: 0px;
    }
}

/* ------- Element A ------- */
.elementA {
    background-color: rgba(38, 62, 27, 0.7);
    color: white;
}
@media (max-width: 575px) {
    .elementA {
        padding: 5px;
    }
}
@media (min-width: 576px) {
    .elementA {
        padding: 15px;
    }
}

/* Slideshow.css */

/* ------- Element B ------- */
.elementB {
    background-image: url(/static/media/home-join.f7ae31f7.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 50%;
}
@media (max-width: 575px) {
    .elementB {
        border: blanchedalmond 5px solid;
    }
}
@media (min-width: 576px) {
    .elementB {
        border: blanchedalmond 10px solid;
    }
}

/* ------- Element C ------- */
.elementC {
    padding: 30px;
    background-color: rgba(121, 44, 37, 0.7);
    color: #f1f1f1;
}
.points-heading {
}
.points-tagline {
}

.points-details {
    padding: 30px;
}

/* ------- Footer Padding ------- */
@media (max-width: 575px) {
    .home-container {
        padding-bottom: 450px;
    }
}
@media (min-width: 576px) {
    .home-container {
        padding-bottom: 45%;
    }
}
@media (min-width: 860px) {
    .home-container {
        padding-bottom: 30%;
    }
}
@media (min-width: 1280px) {
    .home-container {
        padding-bottom: 20vw;
    }
}

.join-text {
    padding: 20px;
}
.join-heading {
    margin-top: 10px;
}

#join-giggle-form-div {
    color: #426b2e;
    display: none;
}
#registered-container {
    display: none;
}
#member-exists-error {
    display: none;
}
.registered-content {
    font-size: 1rem;
}

.join-form {
    padding: 30px;
}

.join-button-container {
}

.join-button {
    width: 50%;
    background-color: #487532;
    color: white;
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
    margin: auto;
}

.join-button:hover {
    /* border-radius: 40px; */
    box-shadow: 2px 2px 5px gray;
    background-color: #426b2e;
    color: #ffffff;
    /* transform: scale(5.5); */
}

.join-the-giggle-form-errors {
    display: none;
    /* visibility: hidden; */
    font-size: 0.8rem !important;
    color: #91180d;
}
@media (max-width: 575px) {
    .join-form {
        padding-top: 0;
    }
    .join-details {
        padding: 0.7rem;
    }
}
@media (min-width: 576px) {
    .join-form {
        padding-top: 50px;
    }
    .join-details {
        padding: 1.7rem;
    }
}

#shoppopup-component {
    position: fixed;
    /* height: 400px; */
    width: 250px;
    left: -500px;
    padding: 20px;
    background-color: blanchedalmond;
    z-index: 830;
    transition: 1s;
    border-radius: 3px;
}
@media (max-width: 575px) {
    #shoppopup-component {
        bottom: 90px;
    }
}

@media (min-width: 576px) {
    #shoppopup-component {
        bottom: 50px;
    }
}

.spopup-container {
    padding: 10px;
}

#spopup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
    cursor: pointer;
}
#spopup-close:hover {
    color: brown;
}

.spopup-icon-container {
    position: relative;
}
.spopup-icon-container > svg {
    margin: auto;
}

.spopup-text {
    font-size: 14px;
    margin: 10px 0px;
}

.spopup-button {
    font-size: 15px;
    width: 100%;
    background-color: #487532;
    color: white;
}
.spopup-button:hover {
    box-shadow: 2px 2px 5px gray;
    color: white !important;
}

.spopup-button-a {
    color: white !important;
    text-decoration: none;
}
.spopup-button-a:hover {
    color: white;
}

.shop-heading {
    margin: auto;
}
.shop-content {
    /* width: 90%; */
    margin: auto;
}

#carouselExampleControls {
    padding: 0;
}
.carousel-image {
    /* height: 50vh; */
    width: 100%;
}
.carousel > button {
    width: 8%;
    opacity: 0.8;
}
.shop-bottom {
    padding: 20px;
    background-color: antiquewhite;
    color: black;
}

@media (max-width: 575px) {
    .shop-heading {
        padding-bottom: 5px;
    }
    .shop-text {
        font-size: 1.2rem;
    }
    .shop-button {
        background-color: antiquewhite;
        width: 100%;
        border: 2px black solid;
        color: black !important;
        padding: 4px 20px;
        text-align: center;
        text-decoration: none;
        font-size: 1rem;
        cursor: pointer;
    }
    .shop-button:hover {
        background-color: #487532;
        border: 1px #487532 solid;
        color: rgb(255, 255, 255) !important;
        padding: 5px 20px;
        box-shadow: 2px 2px 5px gray;
    }
}
@media (min-width: 576px) {
    .shop-heading {
        padding-bottom: 15px;
    }
    .shop-text {
        font-size: 1.2rem;
    }
    .shop-button {
        background-color: antiquewhite;
        width: 80%;
        border: 2px black solid;
        color: black !important;
        padding: 4px 20px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
    }
    .shop-button:hover {
        background-color: #487532;
        border: 1px #487532 solid;
        color: rgb(255, 255, 255) !important;
        padding: 5px 20px;
        box-shadow: 2px 2px 5px gray;
    }
}

/* The side navigation menu */
.sidenav {
    cursor: default;
    height: 100%; /* 100% Full-height */
    width: 25vw; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 850; /* Stay on top */
    top: 0; /* Stay at the top */
    right: -25vw;
    background-color: #1a1a1a; /* Black*/
    color: #d1d1d1; /*Giggle White*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second tranition effect to slide in the sidenav */
}
@media (max-width: 575px) {
    .sidenav {
        right: -100vw;
        width: 100vw; /* 0 width - change this with JavaScript */
    }
}
/* The navigation menu links */
.top-items {
    margin-top: 20px;
}
.top-items a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 1.2rem;
    color: #818181 !important;
    display: block;
    transition: 0.5s;
}

/* When you mouse over the navigation links, change their color */
.top-items a:hover {
    color: #f1f1f1 !important;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    color: #818181;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 2rem;
    margin-left: 50px;
    cursor: pointer;
}

.sidenav .closebtn:hover {
    color: #f1f1f1;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */

#rest-of-page {
    transition: 0.5s;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav-links {
        font-size: 18px;
    }
}
.bottom-items {
    position: absolute;
    bottom: 0px;
    margin: 0px 0px 50px 32px;
    display: block;
    overflow-x: hidden;
}
.contact-container {
    margin-bottom: 20px;
    font-size: 1.5rem;
}
.contact {
    font-size: 1rem;
}
.icons-container {
    margin: auto;
    display: inline;
    display: initial;
    width: 100%;
}
.icons {
    margin: 5px;
    margin-right: 10px;
    color: #f1f1f1;
    transition: 0.3s;
}
.icons:hover {
    color: #818181;
    margin: 3px 8px 3px 3px;
    height: 24px;
    width: 24px;
}

.shop-category-container {
    /* background-color: #ffffff; */
    z-index: 0;
    padding: 3%;
    position: relative;
    font-size: calc(1rem + 0.4vw);
}
.shop-category-container::after {
    position: absolute;
    content: "";
    inset: 0;
    mix-blend-mode: multiply;
    background-color: #124e7c8a;
    z-index: -1;
}
.shop-category-heading {
    color: white;
    margin-bottom: 1%;
}
.shop-categories {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.shop-categories > a:hover {
    text-decoration: none;
    color: #000;
    color: initial;
}
.category-card {
    background: linear-gradient(
        to bottom,
        rgb(245, 245, 245),
        rgb(245, 245, 245),
        rgb(255, 255, 255),
        rgb(255, 255, 255)
    );
    border-radius: 10px;
    margin: 1%;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    height: 200px;
    font-weight: 600;
    position: relative;
}
.category-card:hover {
    box-shadow: 0px 10px 25px 0px #fefffd8a;
}
.category-card:hover .shop-category-icon {
    margin-left: 20%;
    width: 60%;
    max-width: 200%;
    transition: 0.2s;
    opacity: 15% !important;
}
.category-card:hover .shop-category-text {
    transition: 0.2s;
    opacity: 50% !important;
}
.shop-category-icon {
    margin-left: 25%;
    width: 50%;
    /* height: 50%; */
    position: absolute;
    top: 40%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    overflow: hidden;
    transition: 0.2s;
}
.shop-category-icon-img {
    /* position: relative; */
    height: auto;
    width: 80%;
    max-width: 100%;
}
.shop-category-text {
    transition: 0.2s;
    width: 100%;
    position: absolute;
    bottom: 10px;
    overflow: hidden;
}

.shop-category-description-container {
    transition: 0.4s;
    font-size: 0.8rem;
    position: relative;
    height: 100%;
    padding: 5%;
    overflow: hidden;
}
.description-main {
    transition: 0.4s;
    position: absolute;
    bottom: -100%;
    left: 0;
    padding: 5%;
}
.description-tags {
    transition: 0.4s;
    width: 100%;
    position: absolute;
    top: -50%;
    left: 0;
}

/* This break is at an odd number because of maybe scroll bar is 12px wide and it is counted while measuring width */
@media (max-width: 811px) {
    .shop-category-heading {
        padding: 3%;
    }
    .shop-category-headingA {
        font-size: calc(1rem + 0.6vw) !important;
    }
    .shop-category-headingB {
        font-size: calc(0.9rem + 0.4vw) !important;
    }
    .category-card {
        width: 48%;
        height: 150px;
    }
    .description-main {
        display: none;
    }
    .description-tags {
        /* font-size: 0.7rem; */
    }
    .category-card:hover .description-tags {
        transition: 0.4s;
        top: 10%;
    }
    .category-card:hover .description-main {
        transition: 0.4s;
        bottom: 16%;
    }
    .category-card:hover .description-main-accessories {
        bottom: 35%;
    }
}
@media (min-width: 812px) {
    .shop-category-headingA {
        font-size: calc(1.2rem + 0.6vw) !important;
    }
    .shop-category-headingB {
        font-size: calc(1rem + 0.4vw) !important;
    }
    .category-card {
        width: 23%;
        height: 250px;
    }
    .category-card:hover .description-tags {
        transition: 0.4s;
        font-size: 0.9rem;
        top: 10%;
    }
    .category-card:hover .description-main {
        transition: 0.4s;
        bottom: 16%;
    }
    .category-card:hover .description-main-accessories {
        bottom: 35%;
    }
}

.age-container {
    height: 100vh;
}
.age {
    text-align: center;
    margin: auto;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    background-image: url(/static/media/bg1.43946491.jpg);
    background-size: cover;
    color: black;
    /* background: linear-gradient(to bottom, rgba(195, 224, 184, 1), rgba(195, 224, 184, 0)); */
}

.g-logo-container {
    margin-top: 10vh;
    margin-bottom: 5vh;
}
.back {
    margin: 0 auto auto auto;
    background: linear-gradient(to bottom, rgba(195, 224, 184, 0.85), rgba(226, 226, 226, 0.85));
}
@media (max-width: 575px) {
    .g-logo-container {
        height: 10%;
    }
    .back {
        padding: 20px 0;
    }
    .g-logo {
        height: 75px;
        width: 75px;
    }
}
@media (min-width: 576px) {
    .g-logo-container {
        height: 20%;
    }
    .back {
        border-radius: 20px;
        padding: 20px 20px;
    }
    .g-logo {
        height: 100%;
        width: auto;
    }
}
.welcome {
    font-size: 22px;
    font-weight: bold;
    margin: 5px auto;
}
.welcome-logo {
    height: 2.5rem;
    margin-left: 0.5rem;
}
.alabel {
    text-decoration: underline;
    font-weight: 600;
    margin: 20px;
}
.birthday {
    margin: 20px 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.birthday input[type="number"] {
    font-weight: bold;
    font-family: "Poppins";
    text-align: center;
    padding: 10px;
    margin: 15px 5px;
}

.date,
.month {
    width: 63px;
}
.year {
    padding: 10px 8px;
    margin: 15px 5px;
    width: 63px;
}

.rem {
    background-color: #000000;
    margin-top: 50px;
    margin-right: 100px;
}

.remContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 25px;
}
.remChkinv {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.remChk {
    position: relative;
    top: 2px;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #eef7ee;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

/* On mouse-over, add a light green background color */
.remContainer:hover input ~ .remChk {
    background-color: #cbe7cb;
}

/* When the checkbox is checked, add a green background */
.remContainer input:checked ~ .remChk {
    background-color: #306930;
}

/* Create the checkmark/indicator (hidden when not checked) */
.remChk:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.remContainer input:checked ~ .remChk:after {
    display: block;
}

/* Style the checkmark/indicator */
.remContainer .remChk:after {
    left: 10px;
    top: 3px;
    width: 10px;
    height: 19px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.remLabels {
    display: -webkit-flex;
    display: flex;
    text-align: left;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0;
    padding-left: 15px;
    line-height: 18px;
    font-weight: 900;
}
.remLabels label:last-child {
    font-weight: 500;
}

.error {
    color: Red;
}
#invalid-date {
    display: none;
}
.confirm-age {
    margin-bottom: 10px;
    font-weight: 500;
}
.submitBtn {
    margin-top: 15px;
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 5px 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
}

.submitBtn :hover {
    background-color: #2c9f40; /* Green */
}

/* base styles */
.navbar-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
}
.navbar-inside {
    -webkit-align-items: center;
            align-items: center;
    background: linear-gradient(to bottom, rgba(197, 224, 184, 1), rgba(197, 224, 184, 0));
    top: 0;
    z-index: 800;
    height: 85px;
    overflow: visible;
    color: #1a1a1a;
    transition: 0.5s;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.homelink {
    margin: auto;
    padding-top: 20px;
}
.homelink img {
    width: 200px;
}
.navbar-inside a:link,
a:visited {
    text-decoration: none;
    color: #1a1a1a;
}
.navbar-inside a:hover {
    color: #a61b0f;
}
#navbar-small-logo {
    height: 100%;
}
.navbut {
    margin: 20px;
    position: absolute;
    top: 0px;
    cursor: pointer;
}
.leftnavbut {
    padding-left: 20px;
    height: 64px;
}
.rightnavbut {
    padding: 10px;
    right: 0px;
}
.navbut:hover {
    color: #a61b0f;
}
@media (max-width: 575px) {
    .navbut {
        top: 3px;
    }
    .leftnavbut {
        padding-left: 0px;
        top: 5px;
        height: 50px;
    }
}
/* The side navigation menu */

.footer-container {
    padding-top: 50px;
    margin: 0;
    background: url(/static/media/footer-bg1.26d6f01e.png);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 825;
    color: #1a1a1a;
    width: 100%;
}
.footer-content {
    max-width: 90%;
    color: #f2f2f2;
    font-size: 1.2rem;
}

/*--------- FIRST ROW --------- */
.first-row {
    padding: 20px;
}
.first-row div {
    margin: auto;
}
.contact-us {
    margin-bottom: 7px;
    font-size: 1.5rem;
}
.first-row-textB > div {
    display: -webkit-flex;
    display: flex;
}
.footer-links {
    width: auto;
    padding: 0 10px;
}
.footer-links a {
    text-decoration: none;
    color: #f2f2f2;
}
.footer-links a:hover {
    color: #b0b0b0;
}
.first-row-textB {
    padding-top: 15px;
}

/*--------- MIDDLE ROW --------- */
.middle-row {
    margin-bottom: 20px;
    position: relative;
    border-bottom: solid 2px #eaeaea;
}
.footer-logo {
    margin: auto;
    width: 15vw;
    margin-bottom: 1vh;
}
#footer-logo-image {
    width: 100%;
}

/*--------- LAST ROW --------- */
.last-row {
    color: #f2f2f2;
    margin: auto;
}
.last-row div {
    /* margin: auto; */
}
.icons {
    transition: 0s;
}
.icons:hover {
    color: #f2f2f2;
}

/*--------- BREAK AT 545px--------- */
@media (max-width: 545px) {
    .last-row div {
        margin-bottom: 10px;
    }
    .middle-row {
        /* margin: auto; */
    }
    .footer-links {
        font-size: 18px;
    }
}

/*--------- BREAK AT 575px--------- */
@media (max-width: 575px) {
    .first-row-textA,
    .first-row-textB {
        text-align: center !important;
    }
}

/* -------------------- */
@media (min-width: 576px) {
    .first-row-textA {
        text-align: left !important;
    }
    .first-row-textB {
        text-align: right !important;
    }
}

/* base styles */
@media (max-width: 575px) {
    .banner-text {
        font-size: 20px;
    }
    .img {
        width: 100%;
    }
    .positions-container {
        padding: 5px;
        padding-top: 10px;
    }
    .pos-heading {
        margin-left: 5vw;
        font-size: 20px;
    }
    .positions {
        font-size: 16px;
    }
    .expand-button {
        padding-right: 20px;
        cursor: pointer;
    }
    .contract-button {
        padding-right: 20px;
        cursor: pointer;
    }
    .apply-link {
        position: absolute;
        right: 10vw;
    }
    .job-details {
        /* CHANGE IT LATER FOR RESPONSIVE ON MOBILE */
    }
}

@media (min-width: 576px) {
    .banner-text {
        font-size: 30px;
    }
    .img {
        width: 100%;
    }
    .positions-container {
        padding: 20px;
    }
    .pos-heading {
        margin-left: 5vw;
        font-size: 30px;
    }
    .positions {
        font-size: 20px;
    }
    .expand-button {
        padding-right: 20px;
        cursor: pointer;
        /* visibility: hidden; */
        visibility: visible;
    }
    .contract-button {
        padding-right: 20px;
        cursor: pointer;
        visibility: hidden;
        /* visibility: visible; */
    }
    .position {
        margin-left: 10px;
    }
    .apply-link {
        position: absolute;
        right: 10vw;
    }
    .job-details {
        margin-top: 10px;
    }
}
.jobs-container {
    min-height: 150vh;
}
.job-details {
    line-height: 0%;
    /* height: 0; */
    overflow: hidden;
    margin-left: 15vw;
    width: 60%;
    transition: 0.5s;
    opacity: 0%;
}

.job-details li {
    /* margin-bottom: 10px; */
}

#jobs-arrow {
    position: absolute;
    width: 100%;
    text-align: center;
}

.jobs-container {
    height: 100% !important;
}

.box .row {
    border: 1px dotted grey;
}

.box .row.header {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}

.box .row.content {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.box .row.footer {
    -webkit-flex: 0 1 40px;
            flex: 0 1 40px;
}
.positions-container {
    margin-top: 30px;
}

.positions {
    margin-top: 30px;
    margin-left: 10vw;
}

@media (max-width: 575px) {
    .budtender-container {
        padding: 20vmin 10vmin;
    }
    .apply-header-text {
        /* margin-top: 10vmin; */
        padding-top: 8vmin;
        font-size: 18px;
    }
    .close-button {
        padding-top: 7vmin;
        padding-right: 10vmin;
        font-size: 25px;
    }
    .details {
        text-align: justify;
    }
    .desc {
        margin: 0px 0px 15px 0px;
    }
}

@media (min-width: 576px) {
    .budtender-container {
        padding: 10vmin;
        text-align: justify;
    }
    .apply-header-text {
        padding-top: 1vmin;
        margin-top: 0px;
        font-size: 20px;
    }
    .close-button {
        padding-top: 1vmin;
        padding-right: 10vmin;
        font-size: 25px;
    }
    .desc {
        margin: 0px 0px 15px 0px;
    }
}

.close-button {
    display: visible;
}
.apply-container {
    display: -webkit-flex;
    display: flex;
}
.apply-button {
    padding: 15px 30px;
    background-color: #487532;
    color: white;
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
    margin: auto;
}
.apply-button:hover {
    /* border-radius: 40px; */
    box-shadow: 2px 2px 10px gray;
    background-color: #426b2e;
    color: #ffffff;
    /* transform: scale(5.5); */
}
.job-submit-button {
    background-color: #487532;
    color: white;
}

.job-submit-button:hover {
    background-color: #426b2e;
}
.form-label {
    margin: 0;
}
.sub-text {
    font-size: 0.8rem;
}
.error-sub-text {
    font-size: 0.8rem;
    color: red;
    display: none;
}

@media (max-width: 575px) {
    .manager-container {
        padding-top: 20vmin;
    }
}

@media (min-width: 576px) {
    .manager-container {
        padding-top: 20vmin;
    }
}

.contact-us-container {
    height: 100%;
}

.contact-us-element {
    margin: 5vmax 0;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: -webkit-flex;
    display: flex;
}
.contact-us-element0 {
    margin: 5vmax auto;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}
.contact-us-header {
    font-size: calc(1rem + 0.6vw) !important;
    font-weight: bold;
}
.contact-us-header2 {
    font-size: calc(0.8rem + 0.4vw) !important;
}

.contact-us-element1 {
    height: auto;
    margin: auto;
    font-size: 1.5rem;
}
.contact-us-card {
    background: linear-gradient(
        to bottom,
        rgb(245, 245, 245),
        rgb(245, 245, 245),
        rgb(255, 255, 255),
        rgb(255, 255, 255)
    );
    /* background-color: gray; */
    width: 44%;
    border-radius: 10px;
    margin: 0 3%;
    padding: 3%;
    -webkit-align-items: center;
            align-items: center;
}
.contact-us-card:hover {
    box-shadow: 0px 0px 25px 0px #2c481e;
}
.contact-icon {
    padding-bottom: 20px;
}
.bi-telephone-outbound-fill,
.bi-envelope-fill {
}

.contact-us-element2 {
    display: block;
    margin: 10vh auto;
}
.contact-us-form {
    margin: 5vh auto;
    font-size: calc(1rem + 0.6vw) !important;
    visibility: visible;
}
.contact-form-status {
    visibility: hidden;
}

@media (max-width: 575px) {
    .contact-us-elements {
        margin-top: 5%;
    }
    .contact-us-element0 {
        width: 90%;
    }
    .contact-us-element1 {
        width: 90%;
    }

    .contact-us-element2 {
        width: 90%;
    }
}

@media (min-width: 576px) {
    /* .contact-us-elements {
        margin-top: 10px;
    } */
    .contact-us-element0 {
        width: 60%;
    }

    .contact-us-element1 {
        width: 80%;
    }

    .contact-us-element2 {
        width: 80%;
    }
}

.contact-form-mb {
    margin-bottom: 0 !important;
}
/* .contact-form-textarea-container {
} */
.contact-form-textarea {
    height: 150px;
}

.contact-form-submit-button {
    width: 100%;
    background-color: #487532;
    color: rgb(238, 238, 238);
}
.contact-form-submit-button:hover {
    background-color: #426b2e;
    color: white;
    box-shadow: 2px 2px 10px gray;
}
.contact-form-status {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}
@media (max-width: 767px) {
    .contact-us-form {
        width: auto;
    }
}

@media (min-width: 768px) {
    .contact-us-form {
        width: 65%;
    }
}

.about-container {
    background-image: url(/static/media/about-bg1.9ae8ed28.jpg);
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    margin: auto;
    padding-top: 15vh !important;
}
/* .about-1 > .about-image-left {
} */
@media (max-width: 575px) {
    .about-heading {
        display: -webkit-flex;
        display: flex;
    }
    .about-heading-content {
        font-size: 2rem;
        margin: auto;
        padding-bottom: 7vh;
    }
    .about-content {
        width: 100%;
    }
    .about-content > div {
        font-size: 2.5vmax;
    }
    .about-image-container {
        display: -webkit-flex;
        display: flex;
        overflow: hidden;
        height: 50vh;
    }
    .about-images {
        object-fit: cover;
        min-height: 100%;
        max-width: 100%;
    }
    .about-text {
        padding: 5vw 5vw 10vh 5vw;
        text-align: center;
    }
}

@media (min-width: 576px) {
    .about-heading {
        display: -webkit-flex;
        display: flex;
    }
    .about-heading-content {
        font-size: 3.5rem;
        margin: auto;
        padding-bottom: 7vh;
    }
    .about-content {
        position: relative;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        height: 65vh;
    }
    .about-content > div {
        font-size: 1.5vmax;
        width: 50%;
    }
    .about-image-container {
        display: -webkit-flex;
        display: flex;
        overflow: hidden;
        height: 65vh;
    }
    .about-2 {
        right: 0;
        position: absolute;
    }
    .about-images {
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
    }
    .about-text {
        padding: 6.5vh 3.5vw 0 3.5vw;
    }
    .about-text-left {
        text-align: right;
    }
    .about-text-right {
        text-align: left;
    }
}

/* base styles */
@media (max-width: 575px) {
  .banner-text {
    font-size: 20px;
  }
  .img {
    width: 100%;
  }
  .events-container {
    padding: 5px;
    padding-top: 10px;
  }
  .pos-heading {
    margin-left: 5vw;
    font-size: 20px;
  }
  .events {
    font-size: 18px;
  }
}

@media (min-width: 576px) {
  .banner-text {
    font-size: 30px;
  }
  .img {
    width: 100%;
  }
  .events-container {
    padding: 20px;
  }
  .pos-heading {
    margin-left: 5vw;
    font-size: 30px;
  }
  .events {
    font-size: 20px;
  }
  .position {
    margin-left: 10px;
  }
}

.events-container {
  margin-top: 30px;
  margin-bottom: 200px;
}
.events {
  margin-left: 10vw;
}

.store-container {
    margin: auto;
    /* height: 100%; */
    /* max-width: 1440px; */
    margin-top: 2rem;
    font-size: 1.2rem;
}

.stores-heading {
    margin-top: 5rem;
    margin-bottom: 1rem;
    padding-bottom: 12px;
    border-bottom: 2px #80808080 solid;
}
.stores {
    /* width: 720px; */
    padding-top: 12px;
    padding-left: 15px;
    background-color: white;
}
.store {
    padding-right: 15px;
    /* padding-bottom: 50px; */
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
}
.store-button {
    width: 45%;
    margin: 0 2.5%;
}
.disabled {
    cursor: not-allowed;
}
.address-text,
.phone-text {
    font-size: 1rem;
}
.review-container {
    width: auto;
}
.review {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}
.store-row-2 {
    margin: auto;
}
.store-row-2 > a:hover {
    color: #005596;
}

.city {
    width: 100%;
}

.address-icon,
.phone-icon {
    padding-right: 12px;
    padding-top: 0;
}
.phone-text,
.address-text {
    padding-top: 3px;
}

.stores-links {
    text-decoration: none;
}

@media (max-width: 575px) {
    .store-container {
        width: 95%;
    }
    .store-name {
        padding-bottom: 7px;
    }
    .store-row-1 {
        padding: 10px 0;
    }
    .store-row-1 > div {
        text-align: center;
    }
    .address-container {
        display: -webkit-flex;
        display: flex;
        padding-bottom: 7px;
    }
    .phone-container {
        display: -webkit-flex;
        display: flex;
    }
    .store {
        border-bottom: 2px #80808080 solid;
    }
}

@media (min-width: 576px) {
    .store-container {
        width: 80%;
    }
    .store-name {
        padding: 10px;
        text-align: left;
    }
    .store-link {
        padding: 10px;
        text-align: right;
    }
    .address-container {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        display: -webkit-flex;
        display: flex;
    }
    .phone-container {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        display: -webkit-flex;
        display: flex;
    }
}

.order-button-container {
    z-index: 801;
    position: fixed;
    transition: 0.1s;
    font-size: 2rem !important;
}
.order-button {
    font-size: 1.2rem;
    -webkit-animation-name: order-button-bg;
            animation-name: order-button-bg;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}
.order-button-a {
    text-decoration: none;
    -webkit-animation-name: order-button-a;
            animation-name: order-button-a;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}

@media (max-width: 1019px) {
    .order-button {
        font-size: calc(0.3rem + 1vw) !important;
    }
}
@media (min-width: 1020px) {
    .order-button {
        font-size: 1.2rem;
    }
}

@media (max-width: 899px) {
    .order-button-container {
        width: 100%;
        bottom: 0;
        z-index: 900;
    }
    .order-button {
        height: 40px;
        border-radius: 0;
        border: 0px solid transparent;
    }
    .footer-container {
        padding-bottom: 40px;
    }
}

@media (min-width: 900px) {
    .order-button-container {
        width: 10%;
        padding: 0 10px;
        bottom: 30px;
        left: 0;
    }
    .order-button:hover {
        background-color: #426b2e;
        box-shadow: 2px 2px 5px black;
    }
}

@-webkit-keyframes order-button-bg {
    0% {
        background-color: #1a1a1a;
    }
    40% {
        background-color: #487532;
    }
    80% {
        background-color: #a61b0f;
    }
    100% {
        background-color: #f2f2f2;
    }
}

@keyframes order-button-bg {
    0% {
        background-color: #1a1a1a;
    }
    40% {
        background-color: #487532;
    }
    80% {
        background-color: #a61b0f;
    }
    100% {
        background-color: #f2f2f2;
    }
}
@-webkit-keyframes order-button-a {
    0% {
        color: white;
    }
    40% {
        color: white;
    }
    80% {
        color: white;
    }
    100% {
        color: black;
    }
}
@keyframes order-button-a {
    0% {
        color: white;
    }
    40% {
        color: white;
    }
    80% {
        color: white;
    }
    100% {
        color: black;
    }
}


.shop-category-container {
    /* background-color: #ffffff; */
    z-index: 0;
    padding: 3%;
    position: relative;
    font-size: calc(1rem + 0.4vw);
}
.shop-category-container::after {
    position: absolute;
    content: "";
    inset: 0;
    mix-blend-mode: multiply;
    background-color: #124e7c8a;
    z-index: -1;
}
.shop-category-heading {
    color: white;
    margin-bottom: 1%;
}
.shop-categories {
    display: flex;
    flex-wrap: wrap;
}
.shop-categories > a:hover {
    text-decoration: none;
    color: initial;
}
.category-card {
    background: linear-gradient(
        to bottom,
        rgb(245, 245, 245),
        rgb(245, 245, 245),
        rgb(255, 255, 255),
        rgb(255, 255, 255)
    );
    border-radius: 10px;
    margin: 1%;
    align-items: center;
    text-align: center;
    height: 200px;
    font-weight: 600;
    position: relative;
}
.category-card:hover {
    box-shadow: 0px 10px 25px 0px #fefffd8a;
}
.category-card:hover .shop-category-icon {
    margin-left: 20%;
    width: 60%;
    max-width: 200%;
    transition: 0.2s;
    opacity: 15% !important;
}
.category-card:hover .shop-category-text {
    transition: 0.2s;
    opacity: 50% !important;
}
.shop-category-icon {
    margin-left: 25%;
    width: 50%;
    /* height: 50%; */
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    overflow: hidden;
    transition: 0.2s;
}
.shop-category-icon-img {
    /* position: relative; */
    height: auto;
    width: 80%;
    max-width: 100%;
}
.shop-category-text {
    transition: 0.2s;
    width: 100%;
    position: absolute;
    bottom: 10px;
    overflow: hidden;
}

.shop-category-description-container {
    transition: 0.4s;
    font-size: 0.8rem;
    position: relative;
    height: 100%;
    padding: 5%;
    overflow: hidden;
}
.description-main {
    transition: 0.4s;
    position: absolute;
    bottom: -100%;
    left: 0;
    padding: 5%;
}
.description-tags {
    transition: 0.4s;
    width: 100%;
    position: absolute;
    top: -50%;
    left: 0;
}

/* This break is at an odd number because of maybe scroll bar is 12px wide and it is counted while measuring width */
@media (max-width: 811px) {
    .shop-category-heading {
        padding: 3%;
    }
    .shop-category-headingA {
        font-size: calc(1rem + 0.6vw) !important;
    }
    .shop-category-headingB {
        font-size: calc(0.9rem + 0.4vw) !important;
    }
    .category-card {
        width: 48%;
        height: 150px;
    }
    .description-main {
        display: none;
    }
    .description-tags {
        /* font-size: 0.7rem; */
    }
    .category-card:hover .description-tags {
        transition: 0.4s;
        top: 10%;
    }
    .category-card:hover .description-main {
        transition: 0.4s;
        bottom: 16%;
    }
    .category-card:hover .description-main-accessories {
        bottom: 35%;
    }
}
@media (min-width: 812px) {
    .shop-category-headingA {
        font-size: calc(1.2rem + 0.6vw) !important;
    }
    .shop-category-headingB {
        font-size: calc(1rem + 0.4vw) !important;
    }
    .category-card {
        width: 23%;
        height: 250px;
    }
    .category-card:hover .description-tags {
        transition: 0.4s;
        font-size: 0.9rem;
        top: 10%;
    }
    .category-card:hover .description-main {
        transition: 0.4s;
        bottom: 16%;
    }
    .category-card:hover .description-main-accessories {
        bottom: 35%;
    }
}

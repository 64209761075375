.store-container {
    margin: auto;
    /* height: 100%; */
    /* max-width: 1440px; */
    margin-top: 2rem;
    font-size: 1.2rem;
}

.stores-heading {
    margin-top: 5rem;
    margin-bottom: 1rem;
    padding-bottom: 12px;
    border-bottom: 2px #80808080 solid;
}
.stores {
    /* width: 720px; */
    padding-top: 12px;
    padding-left: 15px;
    background-color: white;
}
.store {
    padding-right: 15px;
    /* padding-bottom: 50px; */
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
}
.store-button {
    width: 45%;
    margin: 0 2.5%;
}
.disabled {
    cursor: not-allowed;
}
.address-text,
.phone-text {
    font-size: 1rem;
}
.review-container {
    width: auto;
}
.review {
    width: fit-content;
    margin: auto;
}
.store-row-2 {
    margin: auto;
}
.store-row-2 > a:hover {
    color: #005596;
}

.city {
    width: 100%;
}

.address-icon,
.phone-icon {
    padding-right: 12px;
    padding-top: 0;
}
.phone-text,
.address-text {
    padding-top: 3px;
}

.stores-links {
    text-decoration: none;
}

@media (max-width: 575px) {
    .store-container {
        width: 95%;
    }
    .store-name {
        padding-bottom: 7px;
    }
    .store-row-1 {
        padding: 10px 0;
    }
    .store-row-1 > div {
        text-align: center;
    }
    .address-container {
        display: flex;
        padding-bottom: 7px;
    }
    .phone-container {
        display: flex;
    }
    .store {
        border-bottom: 2px #80808080 solid;
    }
}

@media (min-width: 576px) {
    .store-container {
        width: 80%;
    }
    .store-name {
        padding: 10px;
        text-align: left;
    }
    .store-link {
        padding: 10px;
        text-align: right;
    }
    .address-container {
        justify-content: flex-start;
        display: flex;
    }
    .phone-container {
        justify-content: flex-end;
        display: flex;
    }
}

@media (max-width: 575px) {
    .budtender-container {
        padding: 20vmin 10vmin;
    }
    .apply-header-text {
        /* margin-top: 10vmin; */
        padding-top: 8vmin;
        font-size: 18px;
    }
    .close-button {
        padding-top: 7vmin;
        padding-right: 10vmin;
        font-size: 25px;
    }
    .details {
        text-align: justify;
    }
    .desc {
        margin: 0px 0px 15px 0px;
    }
}

@media (min-width: 576px) {
    .budtender-container {
        padding: 10vmin;
        text-align: justify;
    }
    .apply-header-text {
        padding-top: 1vmin;
        margin-top: 0px;
        font-size: 20px;
    }
    .close-button {
        padding-top: 1vmin;
        padding-right: 10vmin;
        font-size: 25px;
    }
    .desc {
        margin: 0px 0px 15px 0px;
    }
}

.close-button {
    display: visible;
}
.apply-container {
    display: flex;
}
.apply-button {
    padding: 15px 30px;
    background-color: #487532;
    color: white;
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
    margin: auto;
}
.apply-button:hover {
    /* border-radius: 40px; */
    box-shadow: 2px 2px 10px gray;
    background-color: #426b2e;
    color: #ffffff;
    /* transform: scale(5.5); */
}
.job-submit-button {
    background-color: #487532;
    color: white;
}

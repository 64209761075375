.job-submit-button:hover {
    background-color: #426b2e;
}
.form-label {
    margin: 0;
}
.sub-text {
    font-size: 0.8rem;
}
.error-sub-text {
    font-size: 0.8rem;
    color: red;
    display: none;
}

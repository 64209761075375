.contact-us-container {
    height: 100%;
}

.contact-us-element {
    margin: 5vmax 0;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
}
.contact-us-element0 {
    margin: 5vmax auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.contact-us-header {
    font-size: calc(1rem + 0.6vw) !important;
    font-weight: bold;
}
.contact-us-header2 {
    font-size: calc(0.8rem + 0.4vw) !important;
}

.contact-us-element1 {
    height: auto;
    margin: auto;
    font-size: 1.5rem;
}
.contact-us-card {
    background: linear-gradient(
        to bottom,
        rgb(245, 245, 245),
        rgb(245, 245, 245),
        rgb(255, 255, 255),
        rgb(255, 255, 255)
    );
    /* background-color: gray; */
    width: 44%;
    border-radius: 10px;
    margin: 0 3%;
    padding: 3%;
    align-items: center;
}
.contact-us-card:hover {
    box-shadow: 0px 0px 25px 0px #2c481e;
}
.contact-icon {
    padding-bottom: 20px;
}
.bi-telephone-outbound-fill,
.bi-envelope-fill {
}

.contact-us-element2 {
    display: block;
    margin: 10vh auto;
}
.contact-us-form {
    margin: 5vh auto;
    font-size: calc(1rem + 0.6vw) !important;
    visibility: visible;
}
.contact-form-status {
    visibility: hidden;
}

@media (max-width: 575px) {
    .contact-us-elements {
        margin-top: 5%;
    }
    .contact-us-element0 {
        width: 90%;
    }
    .contact-us-element1 {
        width: 90%;
    }

    .contact-us-element2 {
        width: 90%;
    }
}

@media (min-width: 576px) {
    /* .contact-us-elements {
        margin-top: 10px;
    } */
    .contact-us-element0 {
        width: 60%;
    }

    .contact-us-element1 {
        width: 80%;
    }

    .contact-us-element2 {
        width: 80%;
    }
}

#shoppopup-component {
    position: fixed;
    /* height: 400px; */
    width: 250px;
    left: -500px;
    padding: 20px;
    background-color: blanchedalmond;
    z-index: 830;
    transition: 1s;
    border-radius: 3px;
}
@media (max-width: 575px) {
    #shoppopup-component {
        bottom: 90px;
    }
}

@media (min-width: 576px) {
    #shoppopup-component {
        bottom: 50px;
    }
}

.spopup-container {
    padding: 10px;
}

#spopup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
    cursor: pointer;
}
#spopup-close:hover {
    color: brown;
}

.spopup-icon-container {
    position: relative;
}
.spopup-icon-container > svg {
    margin: auto;
}

.spopup-text {
    font-size: 14px;
    margin: 10px 0px;
}

.spopup-button {
    font-size: 15px;
    width: 100%;
    background-color: #487532;
    color: white;
}
.spopup-button:hover {
    box-shadow: 2px 2px 5px gray;
    color: white !important;
}

.spopup-button-a {
    color: white !important;
    text-decoration: none;
}
.spopup-button-a:hover {
    color: white;
}

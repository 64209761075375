/* base styles */
@media (max-width: 575px) {
    .banner-text {
        font-size: 20px;
    }
    .img {
        width: 100%;
    }
    .positions-container {
        padding: 5px;
        padding-top: 10px;
    }
    .pos-heading {
        margin-left: 5vw;
        font-size: 20px;
    }
    .positions {
        font-size: 16px;
    }
    .expand-button {
        padding-right: 20px;
        cursor: pointer;
    }
    .contract-button {
        padding-right: 20px;
        cursor: pointer;
    }
    .apply-link {
        position: absolute;
        right: 10vw;
    }
    .job-details {
        /* CHANGE IT LATER FOR RESPONSIVE ON MOBILE */
    }
}

@media (min-width: 576px) {
    .banner-text {
        font-size: 30px;
    }
    .img {
        width: 100%;
    }
    .positions-container {
        padding: 20px;
    }
    .pos-heading {
        margin-left: 5vw;
        font-size: 30px;
    }
    .positions {
        font-size: 20px;
    }
    .expand-button {
        padding-right: 20px;
        cursor: pointer;
        /* visibility: hidden; */
        visibility: visible;
    }
    .contract-button {
        padding-right: 20px;
        cursor: pointer;
        visibility: hidden;
        /* visibility: visible; */
    }
    .position {
        margin-left: 10px;
    }
    .apply-link {
        position: absolute;
        right: 10vw;
    }
    .job-details {
        margin-top: 10px;
    }
}
.jobs-container {
    min-height: 150vh;
}
.job-details {
    line-height: 0%;
    /* height: 0; */
    overflow: hidden;
    margin-left: 15vw;
    width: 60%;
    transition: 0.5s;
    opacity: 0%;
}

.job-details li {
    /* margin-bottom: 10px; */
}

#jobs-arrow {
    position: absolute;
    width: 100%;
    text-align: center;
}

.jobs-container {
    height: 100% !important;
}

.box .row {
    border: 1px dotted grey;
}

.box .row.header {
    flex: 0 1 auto;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}

.box .row.content {
    flex: 1 1 auto;
}

.box .row.footer {
    flex: 0 1 40px;
}
.positions-container {
    margin-top: 30px;
}

.positions {
    margin-top: 30px;
    margin-left: 10vw;
}

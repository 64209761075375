@media (max-width: 575px) {
    .manager-container {
        padding-top: 20vmin;
    }
}

@media (min-width: 576px) {
    .manager-container {
        padding-top: 20vmin;
    }
}

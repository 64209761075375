/* The side navigation menu */
.sidenav {
    cursor: default;
    height: 100%; /* 100% Full-height */
    width: 25vw; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 850; /* Stay on top */
    top: 0; /* Stay at the top */
    right: -25vw;
    background-color: #1a1a1a; /* Black*/
    color: #d1d1d1; /*Giggle White*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second tranition effect to slide in the sidenav */
}
@media (max-width: 575px) {
    .sidenav {
        right: -100vw;
        width: 100vw; /* 0 width - change this with JavaScript */
    }
}
/* The navigation menu links */
.top-items {
    margin-top: 20px;
}
.top-items a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 1.2rem;
    color: #818181 !important;
    display: block;
    transition: 0.5s;
}

/* When you mouse over the navigation links, change their color */
.top-items a:hover {
    color: #f1f1f1 !important;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    color: #818181;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 2rem;
    margin-left: 50px;
    cursor: pointer;
}

.sidenav .closebtn:hover {
    color: #f1f1f1;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */

#rest-of-page {
    transition: 0.5s;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav-links {
        font-size: 18px;
    }
}
.bottom-items {
    position: absolute;
    bottom: 0px;
    margin: 0px 0px 50px 32px;
    display: block;
    overflow-x: hidden;
}
.contact-container {
    margin-bottom: 20px;
    font-size: 1.5rem;
}
.contact {
    font-size: 1rem;
}
.icons-container {
    margin: auto;
    display: initial;
    width: 100%;
}
.icons {
    margin: 5px;
    margin-right: 10px;
    color: #f1f1f1;
    transition: 0.3s;
}
.icons:hover {
    color: #818181;
    margin: 3px 8px 3px 3px;
    height: 24px;
    width: 24px;
}

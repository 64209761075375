.age-container {
    height: 100vh;
}
.age {
    text-align: center;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("../media/bg1.jpg");
    background-size: cover;
    color: black;
    /* background: linear-gradient(to bottom, rgba(195, 224, 184, 1), rgba(195, 224, 184, 0)); */
}

.g-logo-container {
    margin-top: 10vh;
    margin-bottom: 5vh;
}
.back {
    margin: 0 auto auto auto;
    background: linear-gradient(to bottom, rgba(195, 224, 184, 0.85), rgba(226, 226, 226, 0.85));
}
@media (max-width: 575px) {
    .g-logo-container {
        height: 10%;
    }
    .back {
        padding: 20px 0;
    }
    .g-logo {
        height: 75px;
        width: 75px;
    }
}
@media (min-width: 576px) {
    .g-logo-container {
        height: 20%;
    }
    .back {
        border-radius: 20px;
        padding: 20px 20px;
    }
    .g-logo {
        height: 100%;
        width: auto;
    }
}
.welcome {
    font-size: 22px;
    font-weight: bold;
    margin: 5px auto;
}
.welcome-logo {
    height: 2.5rem;
    margin-left: 0.5rem;
}
.alabel {
    text-decoration: underline;
    font-weight: 600;
    margin: 20px;
}
.birthday {
    margin: 20px 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.birthday input[type="number"] {
    font-weight: bold;
    font-family: "Poppins";
    text-align: center;
    padding: 10px;
    margin: 15px 5px;
}

.date,
.month {
    width: 63px;
}
.year {
    padding: 10px 8px;
    margin: 15px 5px;
    width: 63px;
}

.rem {
    background-color: #000000;
    margin-top: 50px;
    margin-right: 100px;
}

.remContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 25px;
}
.remChkinv {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.remChk {
    position: relative;
    top: 2px;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #eef7ee;
    flex-shrink: 0;
}

/* On mouse-over, add a light green background color */
.remContainer:hover input ~ .remChk {
    background-color: #cbe7cb;
}

/* When the checkbox is checked, add a green background */
.remContainer input:checked ~ .remChk {
    background-color: #306930;
}

/* Create the checkmark/indicator (hidden when not checked) */
.remChk:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.remContainer input:checked ~ .remChk:after {
    display: block;
}

/* Style the checkmark/indicator */
.remContainer .remChk:after {
    left: 10px;
    top: 3px;
    width: 10px;
    height: 19px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.remLabels {
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 0;
    padding-left: 15px;
    line-height: 18px;
    font-weight: 900;
}
.remLabels label:last-child {
    font-weight: 500;
}

.error {
    color: Red;
}
#invalid-date {
    display: none;
}
.confirm-age {
    margin-bottom: 10px;
    font-weight: 500;
}
.submitBtn {
    margin-top: 15px;
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 5px 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
}

.submitBtn :hover {
    background-color: #2c9f40; /* Green */
}
